import React, { useState } from "react";
import { IoIosArrowDown } from "react-icons/io";
import ReCAPTCHA from "react-google-recaptcha";

import { Error, Success, Loader } from "../feedback";
import { Row, Box } from "../util";

export const WebsiteContactForm = () => {
  const [fields, setInputs] = useState({});
  let [success, setForm] = useState(false);
  let [loading, setLoading] = useState(false);
  let [error, setError] = useState(false);
  let [recaptchaChecked, setRecaptchaChecked] = useState(false);

  const recaptchaRef = React.createRef();

  // useEffect(() => {
  //   setInputs({
  //     full_name: window.localStorage.getItem("user_name"),
  //     email: window.localStorage.getItem("user_email"),
  //   });
  // }, []);

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault();
      sendMessage(fields);
    }
  };

  const handleInputChange = (event) => {
    event.persist();
    setInputs((fields) => ({
      ...fields,
      [event.target.name]: event.target.value,
    }));
  };

  const onChange = (value) => {
    if (value) {
      setRecaptchaChecked(true);
    }
  };

  function sendMessage(details) {
    const recaptchaValue = recaptchaRef.current.getValue();

    if (!recaptchaValue) {
      return;
    }

    setLoading(true);

    const contact = {
      name: details.full_name,
      email: details.email,
      phone: details.contact_phone,
      company_name: details.company_name,
      number_of_employees: details.employees,
      message: details.message,
      product_name: "PayHero",
    };

    var xhttp = new XMLHttpRequest();
    var url = process.env.GATSBY_SITE_API + "/zendesk/ticket";

    xhttp.open("POST", url, true);
    xhttp.setRequestHeader("Content-type", "application/json; charset=utf-8");
    xhttp.responseType = "json";
    xhttp.onreadystatechange = function() {
      if (xhttp.readyState === 4) {
        if (xhttp.status === 200) {
          setTimeout(() => {
            setForm(true);
            setLoading(false);
          }, 2000);
        } else {
          setError(
            xhttp.response && xhttp.response.message
              ? xhttp.response.message
              : "Sorry! Something went wrong while sending your message. Please refresh and try again. If the issue persists, please email the team at sales@flexitime.works."
          );
          setLoading(false);
        }
      }
    };

    xhttp.onerror = function(e) {
      setError(
        xhttp.response && xhttp.response.message
          ? xhttp.response.message
          : "Sorry! Something went wrong while sending your message. Please refresh and try again. If the issue persists, please email the team at sales@flexitime.works."
      );
      setLoading(false);
    };

    xhttp.send(JSON.stringify(contact));
  }

  return (
    <>
      <Error message={error} />
      <Loader show={loading} />
      {!success && (
        <form className={loading ? "-isLoading" : ""} onSubmit={handleSubmit}>
          <Box>
            <span className="input-title">Full Name</span>
            <input
              type="text"
              placeholder="Full Name"
              name="full_name"
              aria-label="Enter Full Name"
              value={fields.full_name || ""}
              onChange={handleInputChange}
              required
            />
          </Box>
          <Box>
            <span className="input-title">Email Address</span>
            <input
              type="email"
              placeholder="Email Address"
              name="email"
              aria-label="Enter Email Address"
              value={fields.email || ""}
              onChange={handleInputChange}
              required
            />
          </Box>
          <Row stackGap={40}>
            <Box size={60}>
              <span className="input-title">Company Name</span>
              <input
                type="text"
                placeholder="Company Name"
                name="company_name"
                aria-label="Enter Company Name"
                value={fields.company_name || ""}
                onChange={handleInputChange}
                required
              />
            </Box>
            <Box size={40}>
              <span className="input-title"># of Employees</span>
              <div className="select-wrapper">
                <select
                  name="employees"
                  aria-label="Select Number of Employees"
                  onBlur={handleInputChange}
                  defaultValue="1 - 10"
                  required
                >
                  <option value="1 - 10" selected>
                    1 - 10
                  </option>
                  <option value="11 - 25">11 - 25</option>
                  <option value="26 - 50">26 - 50</option>
                  <option value="51 - 100">51 - 100</option>
                  <option value="101 - 500">101 - 500</option>
                  <option value="500+">500+</option>
                </select>
                <IoIosArrowDown className="dropdown-arrow" />
              </div>
            </Box>
          </Row>
          <Box>
            <span className="input-title">Contact Number (Optional)</span>
            <input
              type="phone"
              placeholder="Contact Number (Optional)"
              name="contact_phone"
              aria-label="Enter Contact Number"
              value={fields.contact_phone || ""}
              onChange={handleInputChange}
            />
          </Box>
          <Box>
            <span className="input-title">Your Message</span>
            <textarea
              css={{ maxWidth: "607px" }}
              placeholder="How can we help?"
              name="message"
              aria-label="Enter Your Message"
              value={fields.message || ""}
              onChange={handleInputChange}
              required
            />
          </Box>
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.GATSBY_GOOGLE_RECAPTCHASITEKEY}
            onChange={onChange}
          />
          <button
            className={`${
              recaptchaChecked && !loading ? "" : "-disabled"
            } darkBlue`}
            type="submit"
          >
            {loading ? "Sending Message..." : "Send Message to Sales"}
          </button>
        </form>
      )}
      <Success
        show={success}
        title="Message Sent"
        message="Your message has been sent to our sales team. We will get
                back to you asap :)"
        customClass="contact-success"
      />
    </>
  );
};
